import * as i from 'types';
import * as React from 'react';
import { graphql } from 'gatsby';

import { ContentArea } from 'common/contentArea';
import { Page, Template } from 'common/layout';

const GeneralPage = ({ data }: GeneralPageProps) => {
  const layoutData = data.contentfulPageGeneral;
  const body = layoutData.body;
  const useSimpleMenu = layoutData.useSimpleMenu;

  return (
    <Template
      metaTitle={layoutData.title}
      metaDescription={layoutData.metaDescription}
      dontIndex={layoutData.dontIndex}
      dontFollow={layoutData.dontFollow}
    >
      <Page useSimpleMenu={useSimpleMenu}>
        <ContentArea contentTypes={body} />
      </Page>
    </Template>
  );
};

export default GeneralPage;

export const query = graphql`
  query GeneralPage($id: String) {
    contentfulPageGeneral(id: { eq: $id }) {
      title
      metaDescription
      dontIndex
      dontFollow
      useSimpleMenu
      slug
      body {
        __typename
        ...GeneralPageBodyFragment
      }
    }
  }
`;

export const generalPageBodyFragment = graphql`
  fragment GeneralPageBodyFragment on Node {
    ...HeaderBlocks
  }
`;

type GeneralPageProps = {
  data: {
    contentfulPageGeneral: i.GeneralPage;
  };
};
